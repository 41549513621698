import htmlIcon from "../images/skillsImgs/html5.png";
import cssIcon from "../images/skillsImgs/css.png";
import jsIcon from "../images/skillsImgs/JavaScript.png";
import reactIcon from "../images/skillsImgs/react.png";
import fmIcon from "../images/skillsImgs/framerMotion.png";
import bootstrapSVG from "../icons/skillsicons/bootstrap.svg";
import nodeSVG from "../icons/skillsicons/nodejs.svg";
import expressSVG from "../icons/skillsicons/expressjsIcon.svg";
import postgresSVG from "../icons/skillsicons/postgresql.svg";
import sqlImg from "../images/skillsImgs/sqlImg.png";
import passportjsImg from "../images/skillsImgs/passportjsImg.png";
import oAuth2Img from "../images/skillsImgs/oAuth2.jpg";
import gitIcon from "../icons/skillsicons/gitIcon.svg";
import githubIcon from "../icons/skillsicons/github.svg";


export const lifeSkills = [
  {
    name: "Leadership",
    icon: null,
  },
  {
    name: "Teamwork",
    icon: null,
  },
  {
    name: "Problem Solving",
    icon: null,
  },
  {
    name: "Adaptability",
    icon: null,
  },
  {
    name: "Communication",
    icon: null,
  },
  {
    name: "Time Management",
    icon: null,
  },
  {
    name: "Attention to Detail",
    icon: null,
  },
  {
    name: "Work Ethic",
    icon: null,
  },
  {
    name: "Integrity",
    icon: null,
  },
  {
    name: "Respect",
    icon: null,
  },
];

export const frontEndSkills = [
  {
    name: "HTML5",
    icon: htmlIcon,
  },
  {
    name: "CSS3",
    icon: cssIcon,
  },
  {
    name: "JavaScript",
    icon: jsIcon,
  },
  {
    name: "React",
    icon: reactIcon,
  },
  {
    name: "Bootstrap",
    icon: bootstrapSVG,
  },
  {
    name: "Framer-Motion",
    icon: fmIcon,
  },
  {
    name: "Responsive Design",
    icon: null,
  },
];

export const backEndSkills = [
  {
    name: "Node.js",
    icon: nodeSVG,
  },
  {
    name: "Express.js",
    icon: expressSVG,
  },
  {
    name: "SQL",
    icon: sqlImg,
  },
  {
    name: "PostgreSQL",
    icon: postgresSVG,
  },
  {
    name: "pgAdmin4",
    icon: null,
  },
  {
    name: "Passport.js",
    icon: passportjsImg,
  },
  {
    name: "Google OAuth2",
    icon: oAuth2Img,
  },
];


export const methodologySkills = [
  {
    name: "Object-Oriented Programming",
    icon: null,
  },
  {
    name: "Model-View-Controller",
    icon: null,
  },
  {
    name: "Test-Driven Development",
    icon: null,
  },
];

export const developmentSkills = [
  {
    name: "Git",
    icon: gitIcon,
  },
  {
    name: "GitHub",
    icon: githubIcon,
  },
  {
    name: "REST APIs",
    icon: null,
  },
];
