export const knightMoves = {
  animate: {
    x: ["0%", "310%"],
    y: ["0%", "140%"],
    transition: {
      delay: 2,
      duration: 2,
      ease: "linear",
    },
  },
};

export const rookMoves = {
  animate: {
    x: ["0%", "-160%"],
    y: ["0%", "0%"],
    transition: {
      delay: 4,
      duration: 2,
      ease: "linear",
    },
  },
};


