import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardUser,
  faCompassDrafting,
  faRulerCombined,
  faHelmetSafety,
  faPersonDigging,
  faArrowUpFromGroundWater,
  faHammer,
  faWrench,
  faTrowelBricks,
  faPaintRoller,  
} from "@fortawesome/free-solid-svg-icons";

export const underConstruction = [
  <FontAwesomeIcon icon={faChalkboardUser} size="2x" />,
  <FontAwesomeIcon icon={faCompassDrafting} size="2x" />,
  <FontAwesomeIcon icon={faRulerCombined} size="2x" />,
  <FontAwesomeIcon icon={faHelmetSafety} size="2x" />,
  <FontAwesomeIcon icon={faPersonDigging} size="2x" />,
  <FontAwesomeIcon icon={faArrowUpFromGroundWater} size="2x" />,
  <FontAwesomeIcon icon={faHammer} size="2x" />,
  <FontAwesomeIcon icon={faWrench} size="2x" />,
  <FontAwesomeIcon icon={faTrowelBricks} size="2x" />,
  <FontAwesomeIcon icon={faPaintRoller} size="2x" />,
];
