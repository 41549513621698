export const paperPlaneSequence = [
  [
    ".paperPlane",
    {
      x: [50, 270],
      y: [90, 95],
      rotate: [0, 0],
      scale: [1, 2, 1],
    },
    { duration: 5, ease: "linear" },
  ],
  [".paperPlane", { rotate: [174] }, { duration: 2, ease: "linear" }],
  [
    ".paperPlane",
    { x: [35], y: [120], scale: [1, 2, 1] },
    { duration: 5, ease: "linear" },
  ],
  [".paperPlane", { rotate: [-30] }, { duration: 2, ease: "linear" }],
  [
    ".paperPlane",
    { x: [170], y: [70], scale: [1, 2, 1] },
    { duration: 5, ease: "linear" },
  ],
  [".paperPlane", { rotate: [-200] }, { duration: 2, ease: "linear" }],
  [
    ".paperPlane",
    { x: [50], y: [90], scale: [1, 2, 1] },
    { duration: 5, ease: "linear" },
  ],
  [".paperPlane", { rotate: [0] }, { duration: 2, ease: "linear" }],
];

